import React, { Fragment, ReactNode, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Confetti from 'react-dom-confetti';


import './Wheel.css';


declare const dsLib: any;



function Segment(seg_number: number, seg_color: string) {
    return { "--i": seg_number, "--clr": seg_color } as React.CSSProperties;
}

function SpinWheel({ setHasWon, setConfetti  }: { setHasWon: (text: boolean) => void, setConfetti: (isActive: boolean) => void }) {

    let wheel = document.querySelector('.wheel') as HTMLDivElement;
    const segments = document.querySelectorAll('.segment');
    const numSegments = segments.length;

    let endAngle = Math.ceil(Math.random() * 3600);
    wheel.style.transform = `rotate(${-endAngle}deg)`;

    const spinBtn = document.querySelector('.spinBtn');
        if (spinBtn)
            spinBtn.classList.add('rotating');

    setTimeout(() => {
        endAngle %= 360;

        const segmentAngle = 360 / numSegments;
        let segmentIndex = 0;

        for (let i = 0; i < numSegments; i++) {
            const segmentStartAngle = i * segmentAngle;
            const segmentEndAngle = (i + 1) * segmentAngle;
            if (endAngle >= segmentStartAngle && endAngle < segmentEndAngle) {
                segmentIndex = i;
            }
        }

        if (segmentIndex === 0 || segmentIndex === 4) {
            setHasWon(true);
            setConfetti(true);
        }

        new dsLib.Alert('alert_info').show();

        if (spinBtn)
            spinBtn.classList.remove('rotating');
    }, 6000);
}

function WinAlert({ hasWon }: { readonly hasWon: boolean }): ReactNode {
    return (
        <button style={{border: 'none', background: 'none'}}>
            {hasWon ? "Tourne la roue et gagne ton lot!": "En attendant abonnez-vous à Bpifrance.IO sur Linkedin !"}
        </button>
    );
}


export const Wheel = () => {    
    const [spinDisabled, setSpinDisabled] = useState(false);
    const [hasWon, setHasWon] = useState(false);
    const [confetti, setConfetti] = useState(false);
    //const [showArrow, setShowArrow] = useState(true);


    const leftConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 20,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        delay: 0,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#20e39e", "#e0f1fc", "#b5ffb5"]
      };
      const rightConfig = {
        angle: 270,
        spread: 360,
        startVelocity: 20,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        delay: 0,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#20e39e", "#e0f1fc", "#b5ffb5"]
      };

    const handleSpinClick = () => {
        if (localStorage.getItem('alreadySpin') !== 'true') {
            localStorage.setItem('alreadySpin', 'true');
            setSpinDisabled(true);
            SpinWheel({ setHasWon, setConfetti });
            
        
            // Supprimer le flou des éléments spécifiques
            const elementsToUnblur = document.querySelectorAll('.header, .container, .footer');
            elementsToUnblur.forEach(el => {
                el.classList.remove('blur');  // Assurez-vous que cette ligne est exécutée
                el.classList.add('no-blur');  // et que cette ligne est également exécutée
            });
            //setShowArrow(false);
        } else {
            window.alert('Tu as déjà fait tourner la roue ;)'); 
        }
       
    };

    return (
        <Fragment>
            {localStorage.getItem('answeredCorrectly') === 'true' ? null : <Navigate to='/'/>}
            <header className='header'>
                <h1 className="pink-border blur">LA GRANDE ROUE</h1>
                {/* <div className="square-container blur">
               HIT THE BUTTON
                </div> */}

            </header>
            {/* Conffetti droite et gauche */}

            <div className="confetti-left">
                <Confetti active={confetti} config={leftConfig}/>
            </div>
            <div className="confetti-right">
                <Confetti active={confetti} config={rightConfig}/>
            </div>

            {/* <main> */}

            <div className='container'>
                <button className="spinBtn no-blur" onClick={handleSpinClick} disabled={spinDisabled}>
                </button>
                <div className="arrow blur"></div>
                <div className='wheel blur'>
                    <div className='segment' style={Segment(1, '#FFCD00')}>
                        <div className='content'>
                            <img src="congrats.png" alt="spin" className="segment-icon"/>
                            <span>Tourne la roue</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(2, '#FFFFFF')}>
                        <div className='content'>
                            <img src="oops.png" alt="spin" className="segment-icon"/>
                            <span>Oops</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(3, '#ececec')}>
                        <div className='content'>
                            <img src="missed.png" alt="spin" className="segment-icon"/>
                            <span>Raté...</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(4, '#FFFFFF')}>
                        <div className='content'>
                            <img src="nice-try.png" alt="spin" className="segment-icon"/>
                            <span>Nice try !</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(5, '#FFCD00')}>
                        <div className='content'>
                            <img src="congrats.png" alt="spin" className="segment-icon"/>
                            <span>Tente ta chance</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(6, '#FFFFFF')}>
                        <div className='content'>
                            <img src="missed.png" alt="spin" className="segment-icon"/>
                            <span>Raté...</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(7, '#ececec')}>
                        <div className='content'>
                            <img src="oops.png" alt="spin" className="segment-icon"/>
                            <span>Oops</span>
                        </div>
                    </div>
                    <div className='segment' style={Segment(8, "#FFFFFF")}>
                        <div className='content'>
                            <img src="nice-try.png" alt="spin" className="segment-icon"/>
                            <span>Nice try !</span>
                        </div>
                    </div>
                </div>
                <section>
                    <div id="alert_info" className="ds-alert ds-alert--info wheel-notif" aria-label="Félicitations !"
                         role="alert" tabIndex={0}>
                        <div className="ds-alert-group">
                            <div className="ds-alert-group__header">
                                <h6 className='Congrats'>{hasWon ? "Félicitations !" : "Retentez une prochaine fois..."}</h6>
                                <button id="close_alert_info">
                                    <span className="ds-icon" aria-hidden="true">close</span>
                                </button>
                            </div>
                            <div className="ds-alert-group__body">
                                <p className="ds-body-2--regular">{WinAlert({hasWon})}</p>
                                <div className="social-icons">
                                    <a href="https://twitter.com/bpifrance_io" className="twitter-icon">
                                        <FontAwesomeIcon icon={faTwitter} style={{color: "#1DA1F2"}}/>
                                    </a>
                                    <a href="https://www.linkedin.com/company/bpifrance-io/" className="linkedin-icon">
                                        <FontAwesomeIcon icon={faLinkedin} style={{color: "#0077b5"}}/>
                                    </a>
                                    <a href="https://talents.bpifrance.fr/nos-opportunites/?equipe=digital-it&criteres=digital-it">Nos
                                        offres</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {<div className='spin-button'>
            </div>}
            <footer className='footer blur'>
                <img src="logo_io.png" alt="Logo" className='bpifrance-logo' style={{width: '150px', height: 'auto'}}/>
                {/* <p>Made by Max Gay & Thibault Babeau</p> */}
            </footer>
        </Fragment>
    );
}
