import React from 'react';
import { Wheel } from './Wheel';


import { Routes, Route} from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Wheel />} />
  </Routes>
  );
};

export default App;



